.szh-accordion__item {
    border: solid 1px var(--colour-background-accent-primary);
    border-radius: var(--spacing-small-half);
    padding: var(--spacing-small-half);
    margin-bottom: var(--spacing-small-half);
    &--expanded {
        .chevron-down {
          transform: rotate(180deg);
        }
    }
    :last-child {
        margin-bottom: 0;
    }
}

.szh-accordion__item-btn {
    border: none;
    background: none;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: bold;
    padding: 0;
    text-align: left;
    width: 100%;
    color: var(--colour-text-primary);
}

.szh-accordion__item-panel {
    color: var(--colour-text-primary);
    > :first-child {
        margin-top: var(--spacing-small-half) !important;
    }
    > :last-child {
        padding-bottom: 0 !important;
    }
}